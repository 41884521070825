<template>
  <div class="text-left">
    <template v-if="FrieghtQuotClient[0].questionId == currentQuizId">
      <div class="row">
        <div class="col-12">
          <label  class="d4w-label">Origin:</label>
          <GoogleMapSuggestions
            :value="addressText"
            :pro="pro"
            :id="'origin-id'"
            @change="
              (item) => {
                options[0].value = JSON.stringify(item);
              }
            "
          ></GoogleMapSuggestions>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Company Name</label>
          <div
            class="input-freight-control px-2 py-1"
  
          >
            <input
              type="text"
              v-model.trim="fromDetail.company_name"
              id="length"
              class="w-100"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Contact Name</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <input
              type="text"
              v-model.trim="fromDetail.contact_name"
              id=""
              class="w-100"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Contact Email</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <input
              type="email"
              @blur="checkEmail(fromDetail.contact_email)"
              v-model.trim="fromDetail.contact_email"
              id=""
              class="w-100"
            />
          </div>
          <i
            v-show="!isValidateEmail && fromDetail.contact_email"
            class="px-2 text-danger"
            >Please enter valid email</i
          >
        </div>
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Contact No.</label>
          <div
            class="input-freight-control overflow-hidden"
            style="padding: 2.5px 0px !important;"
          >
            <VueTelInput
              class="tel-number"
              :inputOptions="phoneInputOption"
              :dropdownOptions="dropdown"
              v-model.trim="fromDetail.contact_no"
              @validate="onValidation"
              mode="us"
              :validCharactersOnly="true"
              :default-country="'us'"
            />
          </div>
        </div>

        <div class="col-12">
          <label for=""  class="d4w-label">Pickup Date</label>

          <DateTime
            :pro="pro"
            :value="options[1].value"
            @change="
              (item) => {
                options[1].value = item;
              }
            "
          ></DateTime>
        </div>
        <!-- <div class=" col-12">
           <div class="d-flex align-items-end h-100">
            <b-form-checkbox
            v-model.trim="fromDetail.is_residential"
            size="lg"
            class="checkbox"><span  class="d4w-label">Is Resident?</span></b-form-checkbox>
          </div> 
        </div> -->

        <div class="col-12 col-md-4">
          <label for=""  class="d4w-label">Business Time Start</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <vue-timepicker
              drop-direction="up"
              hide-clear-button
              :minute-interval="30"
              format="HH:mm"
              close-on-complete
              placeholder="--:--"
              class="timepicker"
              v-model.trim="fromDetail.pickup_window.start_at"
            >
              <template v-slot:icon>
                <img src="https://i.postimg.cc/CLkZcW46/custom-clock.png" />
              </template>
            </vue-timepicker>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label for=""  class="d4w-label">Business Time End</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <vue-timepicker
              drop-direction="up"
              hide-clear-button
              hide-disabled-items
              :hour-range="getCloseTime"
              :minute-interval="30"
              format="HH:mm"
              close-on-complete
              placeholder="--:--"
              class="timepicker"
              v-model.trim="fromDetail.pickup_window.closing_at"
            >
              <template v-slot:icon>
                <img src="https://i.postimg.cc/CLkZcW46/custom-clock.png" />
              </template>
            </vue-timepicker>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label for=""  class="d4w-label">Business Time Close</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <vue-timepicker
              drop-direction="up"
              hide-clear-button
              hide-disabled-items
              :hour-range="getCloseTime"
              :minute-interval="30"
              format="HH:mm"
              close-on-complete
              placeholder="--:--"
              class="timepicker"
              v-model.trim="fromDetail.pickup_window.end_at"
            >
              <template v-slot:icon>
                <img src="https://i.postimg.cc/CLkZcW46/custom-clock.png" />
              </template>
            </vue-timepicker>
          </div>
        </div>
      </div>
    </template>
    <!-- Destination -->
    <template v-else-if="FrieghtQuotClient[3].questionId == currentQuizId">
      <div class="row">
        <div class="col-12">
          <label  class="d4w-label">Destination:</label>
          <GoogleMapSuggestions
            :destination="destinationObj"
            :pro="pro"
            :value="addressText"
            :id="'origin-id'"
            @change="
              (item) => {
                options[0].value = JSON.stringify(item);
              }
            "
          ></GoogleMapSuggestions>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Company Name</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <input
              type="text"
              v-model.trim="fromDetail.company_name"
              id="length"
              class="w-100"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Contact Name</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <input
              type="text"
              v-model.trim="fromDetail.contact_name"
              id=""
              class="w-100"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Contact Email</label>
          <div
            class="input-freight-control px-2 py-1"
            
          >
            <input
              type="email"
              @blur="checkEmail(fromDetail.contact_email)"
              v-model.trim="fromDetail.contact_email"
              id=""
              class="w-100"
            />
          </div>
          <i
            v-show="!isValidateEmail && fromDetail.contact_email"
            class="px-2 text-danger"
            >Please enter valid email</i
          >
        </div>
        <div class="col-12 col-md-6">
          <label for=""  class="d4w-label">Contact No.</label>
          <div
            class="input-freight-control overflow-hidden"
            style="padding: 2.5px 0px !important;"
          >
            <VueTelInput
              class="tel-number"
              :inputOptions="phoneInputOption"
              :dropdownOptions="dropdown"
              v-model.trim="fromDetail.contact_no"
              @validate="onValidation"
              mode="us"
              :validCharactersOnly="true"
              :default-country="'us'"
            />
          </div>
        </div>

        <!-- <div class="col-12 col-md-6">
          <div class="d-flex align-items-end h-100 w-100">
            <b-form-checkbox
              v-model.trim="fromDetail.is_residential"
              class="checkbox"><span  class="d4w-label">Is Resident?</span></b-form-checkbox>
          </div>
        </div> -->
      </div>
    </template>
    <template v-else-if="FrieghtQuotClient[1].questionId == currentQuizId">
      <div
        class="freight-container mt-3 py-3"
        v-for="(packag, index) in packages"
        :key="index"
      >
        <div
          class="d-flex w-100 justify-content-between"
          v-b-toggle="'Package' + index"
          style="position: absolute; top: -13px; font-size: 14px"
        >
          <div
            title="Remove"
            class="package-title"
            style="background: #fff; cursor: pointer"
          >
            <span class="open-chevron">
              <i class="fa-solid fa-angle-down"></i>
            </span>
            <span class="close-chevron">
              <i class="fa-solid fa-angle-right"></i>
            </span>
            Package {{ index + 1 }}
          </div>
          <div
            class="trash-box"
            v-show="index > 0"
            @click="packages.splice(index, 1)"
          >
          <i class="fa-solid fa-circle-xmark"></i>
          
          </div>
        </div>
        <b-collapse
          visible
          :id="'Package' + index"
          class="px-md-3"
          accordion="accordion-collapse"
        >
          
          <div class="row">
            <div class="col-6 col-md-3 ">
              <div class="">
                <label  class="d4w-label" for="length">Length</label>
                <div
                  class="input-freight-control px-2 py-1"
                  
                >
                  <input
                    type="number"
                    v-model.number="packag.length"
                    id="length"
                    class="w-100"
                  />
                </div>
                <!-- <i class="text-danger" v-if="packag.length==dimensionValue">Max: {{dimensionValue}}</i> -->
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="">
                <label  class="d4w-label" for="width">Width</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <input
                    type="number"
                    v-model.number="packag.width"
                    id="width"
                    class="w-100"
                  />
                  <!-- <div class="measure-point">in</div> -->
                </div>
                <!-- <i class="text-danger" v-if="packag.width==dimensionValue">Max: {{dimensionValue}}</i> -->
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="">
                <label  class="d4w-label" for="Height">Height</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <input
                    type="number"
                    v-model.number="packag.height"
                    id="Height"
                    class="w-100"
                  />
                  <!-- <div class="measure-point">in</div> -->
                </div>
                <!-- <i class="text-danger" v-if="packag.height==dimensionValue">Max: {{dimensionValue}}</i> -->
              </div>
            </div>
            <div class="col-6 col-md-3">
              <!-- Select Box -->
              <div class="">
                <label  class="d4w-label" for="unit">Unit</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <select
                    v-model="packag.dimension_unit"
                    class="w-100"
                    id="unit"
                  >
                    <option disabled value="">Unit</option>
                    <option value="inch">Inch</option>
                    <option value="centimeter">Centimeter</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 24px;">
            <div class="col-6 col-md-9">
              <div class="">
                <label  class="d4w-label" for="weight">Weight</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <input
                    type="number"
                    v-model.number="packag.weight"
                    id="weight"
                    class="w-100"
                  />
                </div>
                <!-- <i class="text-danger" v-if="packag.weight==convertPoundsToUnit(150,packag.weight_unit)">Max: {{convertPoundsToUnit(150,packag.weight_unit)+' '+packag.weight_unit}} </i> -->
              </div>
            </div>
            <div class="col-6 col-md-3">
              <!-- Select Box -->

              <div class="">
                <label  class="d4w-label" for="weight-unit">Unit</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <select
                    v-model="packag.weight_unit"
                    class="w-100"
                    id="weight-unit"
                  >
                    <option disabled value="">Unit</option>
                    <option value="gram">Gram</option>
                    <option value="kilogram">Kilogram</option>
                    <option value="ounce">Ounce</option>
                    <option value="pound">Pound</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6 col-md-4">
              <div class="">
                <label  class="d4w-label" for="class">Class</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <select class="w-100" v-model="packag.class" id="Type">
                    <option value="" disabled>Class</option>
                    <option
                      v-for="(label, value) in classOptions"
                      :key="value"
                      :value="value"
                    >
                      {{ value + " - " + label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-6 col-md-4">
              <div class="">
                <label  class="d4w-label" for="Type">Type</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <select name="" v-model="packag.type" id="Type">
                    <option value="" disabled selected>Type</option>
                    <option value="Pallet">Pallets</option>
                    <option value="Box">Box</option>
                    <option value="Crate">Crate</option>
                    <option value="Bundle">Bundle</option>
                    <option value="Drum">Drum</option>
                    <option value="Roll">Roll</option>
                    <option value="Bale">Bale</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="">
                <label  class="d4w-label" for="Quantity">Quantity</label>
                <div
                  class="input-freight-control px-2 py-1 d-flex align-items-center"
                  
                >
                  <input
                    v-model.number="packag.quantity"
                    type="number"
                    id="Quantity"
                    class="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-4">
              <div class="d-flex align-items-end h-100">
                <b-form-checkbox size="lg" class="checkbox" v-model="packag.stackable"
                  ><span  class="d4w-label">Stackable</span></b-form-checkbox
                >
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="d-flex align-items-end h-100">
                <b-form-checkbox
                  class="checkbox" size="lg"
                  v-model="packag.hazardous_materials"
                  ><span  class="d4w-label">Hazardous Materials</span
                  ></b-form-checkbox
                >
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label  class="d4w-label" for="Description">Description</label>
              <div
                class="input-freight-control px-2 py-1 d-flex align-items-center"
                
              >
                <textarea
                  v-model="packag.description"
                  class="description-textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items px-2 justify-content-end">
        <div class="freight-link" @click="pushPackage">
         + Add Package
        </div>
      </div>
        </div>
      </div>
   
    </template>
    <template v-else>
      <FrieghtQuotServices
        :selected="selectedServices"
        @change="changeServices"
        :pro="pro"
      ></FrieghtQuotServices>
    </template>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import DateTime from "../components/DateTime.vue";
import GoogleMapSuggestions from "../components/GoogleMapSuggestions.vue";
import FrieghtQuotServices from "./FrieghtQuotServices.vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { mapGetters } from "vuex";
// import InputWithSuggestion from '../components/InputWithSuggestion.vue'
export default {
  props: {
    pro: Object,
    FrieghtQuotClient: Array,
    currentQuizId: Number,
  },
  components: {
    // InputWithSuggestion,
    VueTimepicker,
    GoogleMapSuggestions,
    DateTime,
    FrieghtQuotServices,
    VueTelInput,
  },
  data() {
    return {
      dimensionValue: 999,
      isValidateEmail: true,
      addressText: null,
      phoneValid: false,
      phoneInputOption: {
        placeholder: "Enter Phone Number",
        maxlength: 15,
      },
      dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },
      toDetail: {
        pickup_window: {
          start_at: "00:00",
          end_at: "00:00",
          closing_at: "00:00",
        },
        company_name: "",
        contact_name: "",
        contact_no: "",
        is_residential: false,
      },
      fromDetail: {
        pickup_window: {
          start_at: "00:00",
          end_at: "00:00",
          closing_at: "00:00",
        },
        company_name: "",
        contact_name: "",
        contact_no: "",
        contact_email: "",
        is_residential: false,
      },
      classOptions: {
        50: "Bricks, cement, hardwood flooring",
        55: "Car parts, bottled beverages",
        60: "Wooden furniture parts",
        65: "Auto parts, paper products",
        70: "Machinery, appliances",
        77.5: "Cabinets, shelving units",
        85: "Household appliances, motorcycles",
        92.5: "Furniture, assembled office chairs",
        100: "Clothing, car tires",
        110: "Bicycles, steel tubing",
        125: "Refrigerators, rubber goods",
        150: "Office furniture, motorcycles with sidecars",
        175: "Mattress sets, aluminum cookware",
        200: "Plastic products, small engines",
        250: "Sofas, large furniture",
        300: "Large machinery parts, large plastic containers",
        400: "Mattresses, large plastic products",
        500: "Ping pong balls, very light large packaging",
      },
      packages: [
        {
          weight: "", // lbs
          quantity: 1,
          width: "", //inches
          length: "", //inches
          height: "", //inches
          type: "",
          weight_unit: "pound",
          dimension_unit: "inch",
          description: "",
          stackable: false,
          hazardous_materials: false,
          class: "",
        },
      ],
      placeholders: [
        { label: "Origin", placeholder: "Please enter full address*" },
        {
          label: "Destination",
          placeholder: "Please enter full address*",
        },
      ],
      counter: 1,
      isInputFocused: -1,
      options: [...this.pro.options],
      selectedServices: [],
      suggestions: [
        "Create a 5-question quiz to match customers with ideal beauty products based on their preferences and skin type.",
        "Design a quick quiz to determine which famous historical figure a person resembles, based on their choices and interests.",
        "Set up a short quiz on solar system facts, using true/false and multiple choice questions to test knowledge.",
      ],
      listActive: false,
      step1Text: "",
    };
  },

  computed: {
    getCloseTime() {
      let hour = parseInt(this.fromDetail.pickup_window.start_at.split(":")[0]);
      hour = hour >= 24 ? 0 : hour + 1;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      return [[hour, 24]];
    },
    ...mapGetters(["getSelectedOptions"]),
    destinationObj() {
      const selectedOptionsMap = new Map(
        this.getSelectedOptions.questionAnswer.map((option) => [
          option.questionId,
          option,
        ])
      );
      if (selectedOptionsMap.has(this.FrieghtQuotClient[0].questionId)) {
        const question1 = selectedOptionsMap.get(
          this.FrieghtQuotClient[0].questionId
        );

        return JSON.parse(question1.selectedOption[0].value);
      }
      return "";
    },
    getOriginDate() {
      const selectedOptionsMap = new Map(
        this.getSelectedOptions.questionAnswer.map((option) => [
          option.questionId,
          option,
        ])
      );
      if (selectedOptionsMap.has(this.FrieghtQuotClient[0].questionId)) {
        const question1 = selectedOptionsMap.get(
          this.FrieghtQuotClient[0].questionId
        );

        return question1.selectedOption[1].value;
      }
      return "";
    },
    allFieldsFilled() {
      return this.checkAllFields(this.fromDetail);
    },
    optionsValue() {
      return this.allFieldsFilled ? JSON.stringify(this.fromDetail) : "";
    },
    style() {
      return this.pro.style;
    },
    checkIsValidPackage() {
      let checkButton = true;

      for (let pkg of this.packages) {
        for (let key in pkg) {
          if (
            (pkg[key] < 1 || pkg[key] === "") &&
            key != "type" &&
            key != "hazardous_materials" &&
            key != "stackable"
          ) {
            checkButton = false;
          }
          if (key == "type" && pkg[key] === "") checkButton = false;
        }
      }
      return checkButton;
    },
  },
  watch: {
    fromDetail: {
      handler(newValue) {
        if (
          this.allFieldsFilled &&
          this.validateEmail(newValue.contact_email)
        ) {
          this.options[this.options.length - 1].value =
            JSON.stringify(newValue);
        } else {
          this.options[this.options.length - 1].value = "";
        }
      },
      deep: true,
    },
    checkIsValidPackage(newValue) {
      if (this.currentQuizId == this.FrieghtQuotClient[2].questionId) {
        return false;
      }
      if (newValue) {
        let selectedOption = { ...this.options[0] };
        selectedOption.value = this.packages;
        this.$emit("selectedOptions", [selectedOption]);
      } else {
        this.$emit("selectedOptions", []);
      }
    },

    options: {
      handler(newValue) {
        if (this.currentQuizId == this.FrieghtQuotClient[2].questionId) {
          return false;
        }
        let checkAllFields = true;
        newValue.forEach((option) => {
          if (!option.value || option.value == "null") {
            checkAllFields = false;
          }
        });
        if (checkAllFields) this.$emit("selectedOptions", newValue);
        else this.$emit("selectedOptions", []);
      },
      deep: true,
    },
  },
  methods: {
    convertPoundsToUnit(pounds, unit) {
      const conversionRates = {
        gram: 453.592,
        kilogram: 0.453592,
        ounce: 16,
      };
      return pounds * (conversionRates[unit] || 1);
    },
    checkAllFields(obj) {
      for (let key in obj) {
        if (key == "start_at" || key == "close_at" || key == "end_at") {
          if (obj[key].split(":")[0] == "HH") {
            return false;
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          if (!this.checkAllFields(obj[key])) {
            return false;
          }
        } else if (obj[key] === "") {
          return false;
        }
      }
      return true;
    },
    onValidation(e) {
      if (e.valid !== undefined) {
        this.phoneValid = !e.valid;
      } else {
        this.phoneValid = false;
      }
    },
    changeServices(services) {
      let selectedOption = [];
      if (services.length > 0) {
        this.options[0].value = JSON.stringify(services);
        selectedOption = [{ ...this.options[0] }];
      } else {
        this.options[0].value = "";
      }

      this.$emit("selectedOptions", selectedOption);
    },
    checkEmail(email) {
      this.isValidateEmail = this.validateEmail(email);
    },
    validateEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    },
    pushPackage() {
      this.packages.push({
        weight: "", // lbs
        quantity: 1,
        width: "", //inches
        length: "", //inches
        height: "", //inches
        type: "",
        description: "",
        stackable: false,
        hazardous_materials: false,
        dimension_unit: "",
        weight_unit: "",
        class: "",
      });
    },
    onSearchboxFocus() {
      this.listActive = true;
      document.addEventListener("click", this.onDocumentClick);
    },
    onDocumentClick(event) {
      if (this.$refs.container)
        if (!this.$refs.container.contains(event.target)) {
          this.listActive = false;
        }
    },
    userInputControlStye(index) {
      const { borderRadius, color, fontSize, fontWeight, selectedTab } =
        this.style;
      return {
        "border-radius": borderRadius + "px",
        color: color,
        fontSize: fontSize + "px",
        fontWeight: fontWeight,
        borderColor:
          this.isInputFocused == index
            ? selectedTab.backgroundColor + "!important"
            : "",
      };
    },
    circleStyle(index) {
      const { selectedTab } = this.style;
      const checkIndex = this.isInputFocused == index;
      return {
        background: checkIndex
          ? selectedTab.backgroundColor + "!important"
          : "#F3F1EB",
        color: checkIndex ? selectedTab.textColor + "!important" : "inherit",
      };
    },
  },
  mounted() {
    if (
      this.FrieghtQuotClient[3].questionId == this.currentQuizId &&
      typeof this.fromDetail.pickup_window == "object"
    ) {
      delete this.fromDetail.pickup_window;
    } else {
      this.fromDetail.pickup_window = {
        start_at: "00:00",
        end_at: "HH:00",
        closing_at: "HH:00",
      };
    }

    if (
      (this.FrieghtQuotClient[0].questionId == this.currentQuizId ||
        this.FrieghtQuotClient[3].questionId == this.currentQuizId) &&
      this.options[this.options.length - 1].value != ""
    ) {
      this.fromDetail = JSON.parse(this.options[this.options.length - 1].value);
      const { address_line1, city, state, country } = this.options[0].value
        ? { ...JSON.parse(this.options[0].value), country: "USA" }
        : { address_line1: "", city: "", state: "", country: "" };
      this.addressText = `${address_line1 || ""}, ${city ? city + "," : ""} ${
        state ? state + "," : ""
      } ${country}`;
    } else if (
      this.FrieghtQuotClient[1].questionId == this.currentQuizId &&
      this.pro.selectedOption.length
    ) {
      this.packages = this.pro.selectedOption[0].value;
    } else if (
      this.FrieghtQuotClient[2].questionId == this.currentQuizId &&
      this.options[0].value
    ) {
      this.selectedServices = JSON.parse(this.options[0].value);
      this.changeServices(this.selectedServices);
    }
  },
};
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
.timepicker,
.timepicker:active {
  border: none;
  box-shadow: none;
}
.freight-container {
  border-top: 1.5px solid #E6E5E5;

  position: relative;
}

.freight-link {
    padding: 8px;
    border-radius: 8px;
    background: #f6f6f8;
    color: #73738d;
    justify-content: center;
    align-items: center;
    gap: 4px;
    display: flex;
    border: none;
    font-weight: 600;
    border: 1px solid var(--border-color);
    font-size: 14px;
    cursor: pointer;
}

.d4w-label {
  margin-bottom: 4px;
  color: #303030;
  font-size: 13px;
  font-weight: 450;
  text-align: center;
  line-height: 20px;
}

.measure-point {
  color: #606060;
  font-size: 14px;
}

.input-freight-control {
  border: 0.66px solid #8A8A8A;
  padding:6px 12px 6px 12px!important;
  border-radius: 8px;
}

.input-freight-control input,
.input-freight-control select {
  border: none;
  outline: none;
  flex: 1;
  background: transparent;
}

.input-freight-control input:focus-visible,
.input-freight-control select:focus-visible {
  border: none;
  outline: none;
}

.rectangle-list span:hover {
  background: #eee;
}

.rectangle-list .active:before {
  background: #4d1b7e !important;
  color: #fff;
}

.rectangle-list span:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: -2.5em;
  top: 50%;
  margin-top: -1em;

  background: #eee;
  height: 2em;
  width: 2em;
  color: #0c0c0c;
  line-height: 2em;
  text-align: center;
  font-size: 14px;
}

.rectangle-list span:after {
  position: absolute;
  content: "";
  border: 0.5em solid transparent;
  left: -1em;
  top: 50%;
  margin-top: -0.5em;
  transition: all 0.3s ease-out;
}

.rectangle-list span:hover:after {
  left: -0.5em;
  border-left-color: #ccc;
}

.rectangle-list span.active:after {
  left: -0.5em;
  border-left-color: #4d1b7e;
}

.suggestion-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  /* Adjust max-height as needed */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.trash-box {
  color: red;
 
 
  /* padding: 0px; */
  font-size: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.custom-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* Add your custom CSS styling here */
.popover-list {
  list-style-type: none;
  padding: 0;

  position: absolute;
  z-index: 11111111111;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  max-height: 250px;
  min-height: 50px;
  overflow-y: scroll;
}

.popover-item {
  padding: 8px;
}

.popover-item:hover {
  background-color: #f0f0f0;
}

.filterSearch {
  padding: 8px;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 4px;
  margin-bottom: 8px;
  margin: 0px;
}

.multiFilter:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}

.input-number {
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.user-input input {
  height: 15px;
  width: 100%;
  border: none;
}

.user-input input:focus-visible {
  border: none;
  outline: none;
}
.tel-number {
  flex: 1;
  border: none;
}
.tel-number:focus-within {
  outline: none;
  border: none;
  box-shadow: none;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #303030;
  background-color: #303030 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #303030;
}
.description-textarea,
.description-textarea:focus-visible {
  height: 60px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
}
.package-title {
  padding: 3px 4px 2px 0px;
  font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
}
.collapsed > .package-title > .open-chevron,
:not(.collapsed) > .package-title > .close-chevron {
  display: none !important;
}
.timepicker {
  display: inline-block;
  position: relative;
  font-size: 1em;
  width: 100%;
  font-family: sans-serif;
  vertical-align: middle;
}
.timepicker >>> input.display-time {
  border: none;
  width: 100%;
  height: 100%;
  padding: 0px 32px;
  font-size: 1em;
}
.timepicker >>> input.display-time {
  outline: none;
}
i.text-danger {
  font-size: 14px;
}
.col-12{
  margin-bottom: 24px;
}

</style>
SquareX Safe File Viewer ✕
