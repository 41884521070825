<template>
  <div class="datetime-picker w-100">
    <!-- <input
      class="w-100 defaultCalendar px-2 py-1"
      type="date"
      v-model="datePickerValue"
      :style="[{borderRadius:'8px',border:'1px solid '+pro.style.color}]"
      :min="calculateMinDate"
      :max="calculateMaxDate"
      @click="openCalendarPicker"
    /> -->
    <DateRangePicker :calculate-position="calculatePosition" ref="datePicker"  class="datePicker w-100"  :date-format="dateFormat" style="    background: rgb(253, 253, 253);
    border: 0.66px solid rgb(138, 138, 138);
    width: 100%;
    border-radius: 8px;
   "
    :locale-data="{ firstDay: 0, format: 'mm/dd/yyyy', daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], }" :max-date="calculateMaxDate" :min-date="calculateMinDate" :ranges="false" :auto-apply="true"	 v-model="range" :singleDatePicker="true">
  
    <template v-slot:input="picker">
           <div class="d-flex align-items-center justify-content-between h-100" style="padding: 6px 12px 6px 12px !important; border-radius: 8px;">
            <span v-if="picker.startDate"
               >{{ customDateFormat(picker.startDate) }}</span
             >
             <span v-else class="">Select Date</span>
             <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 3.5C7.75 3.08579 7.41421 2.75 7 2.75C6.58579 2.75 6.25 3.08579 6.25 3.5V3.90665C6.00413 3.96421 5.77128 4.04472 5.54754 4.15873C4.84193 4.51825 4.26825 5.09193 3.90873 5.79754C3.68239 6.24175 3.58803 6.7219 3.54336 7.26853C3.49999 7.79944 3.49999 8.45505 3.5 9.26789V10.7321C3.49999 11.5449 3.49999 12.2006 3.54336 12.7315C3.58803 13.2781 3.68239 13.7582 3.90873 14.2025C4.26825 14.9081 4.84193 15.4817 5.54754 15.8413C5.99175 16.0676 6.4719 16.162 7.01853 16.2066C7.54944 16.25 8.20505 16.25 9.01788 16.25L12.4013 16.25C12.7236 16.25 12.943 16.2501 13.1364 16.23C14.9018 16.0472 16.2972 14.6518 16.48 12.8864C16.5001 12.693 16.5 12.4736 16.5 12.1514L16.5 9.26788C16.5 8.45505 16.5 7.79944 16.4566 7.26853C16.412 6.7219 16.3176 6.24175 16.0913 5.79754C15.7317 5.09193 15.1581 4.51825 14.4525 4.15873C14.2287 4.04472 13.9959 3.96421 13.75 3.90665V3.5C13.75 3.08579 13.4142 2.75 13 2.75C12.5858 2.75 12.25 3.08579 12.25 3.5V3.75859C11.8765 3.74999 11.456 3.75 10.9821 3.75H9.0179C8.54396 3.75 8.12348 3.74999 7.75 3.75859V3.5ZM6.22852 5.49524C6.42604 5.3946 6.68681 5.32547 7.14068 5.28838C7.60331 5.25058 8.19755 5.25 9.05 5.25H10.95C11.8025 5.25 12.3967 5.25058 12.8593 5.28838C13.3132 5.32547 13.574 5.3946 13.7715 5.49524C14.1948 5.71095 14.5391 6.05516 14.7548 6.47852C14.8554 6.67604 14.9245 6.9368 14.9616 7.39068C14.9764 7.57123 14.9855 7.77183 14.991 8H5.00895C5.01455 7.77183 5.02363 7.57123 5.03838 7.39068C5.07547 6.9368 5.1446 6.67604 5.24524 6.47852C5.46095 6.05516 5.80516 5.71095 6.22852 5.49524ZM5 9.5V10.7C5 11.5525 5.00058 12.1467 5.03838 12.6093C5.07547 13.0632 5.1446 13.324 5.24524 13.5215C5.46095 13.9448 5.80516 14.289 6.22852 14.5048C6.42604 14.6054 6.68681 14.6745 7.14068 14.7116C7.60331 14.7494 8.19755 14.75 9.05 14.75H12.3557C12.7407 14.75 12.8759 14.749 12.9818 14.738C14.0411 14.6283 14.8783 13.7911 14.988 12.7318C14.999 12.6259 15 12.4907 15 12.1057V9.5H5Z" fill="#8A8A8A"/>
</svg>

           </div>
             
              <b class="caret"></b>
            </template>
  </DateRangePicker>
    <div v-if="isError"><i class="text-danger">{{errorMessage}}</i></div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  components: { DateRangePicker },
  props: {
    minDate: {
      type: String,
      default: ''
    },
    pro: {
      type: Object,
      required: true
    },
    value:String,
  },
  data() {
    return {
      range: {
        startDate: this.value||'',
        endDate: this.value||'',
      },
      datePickerValue: this.value||'',
      isError:false,
      errorMessage:'',
    };
  },
  computed: {
    calculateMinDate() {
     

  return  this.addDays(new Date(), 0).toISOString().substr(0, 10);
},
 calculateMaxDate() {
  return this.addDays(new Date(), 30).toISOString().substr(0, 10);
},
checkSunday(){
  const dateObj = new Date(this.datePickerValue);
  return (dateObj.getDay() === 0)
}
  },
  watch: {
    datePickerValue(newValue) {
      const minDate = new Date(this.calculateMinDate);
      const maxDate = new Date(this.calculateMaxDate)
      const inputDate = new Date(newValue);

      if (inputDate < minDate && inputDate>maxDate) {
        this.errorMessage='The selected date must be today or within the next 30 days.'
       this.isError=true
       this.$emit('change', null);
       
      }
      
      else
      {
        this.isError=false
        this.errorMessage = ''
        this.$emit('change', newValue);
      }
    }
  },
  methods: {
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    calculatePosition(dropdownList, component, { top, left, right }) {
      const pickerRect = component.$el.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const dropdownHeight = dropdownList.offsetHeight;

      const spaceBelow = viewportHeight - pickerRect.bottom;
      const spaceAbove = pickerRect.top;

      let newTop = top; // Start with initial top

      if (spaceBelow >= dropdownHeight) {
        // Enough space below picker control
        newTop = pickerRect.bottom + window.scrollY;
      } else if (spaceAbove >= dropdownHeight) {
        // Enough space above picker control
        newTop = pickerRect.top - dropdownHeight + window.scrollY;
      } else if (spaceBelow >= spaceAbove) {
        // Position below with overflow
        newTop = pickerRect.bottom + window.scrollY;
      } else {
        // Position above with overflow
        newTop = pickerRect.top - dropdownHeight + window.scrollY;
      }

      dropdownList.style.top=`${newTop}px`
      return { top: newTop, left, right };
    },
    dateFormat(classes, date) {
  // Disable Sundays
  if (date.getDay() === 0) {
    classes.disabled = true;
  } 
  return classes;
},
    customDateFormat(date) {
      const inputDate = new Date(date);
            const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = inputDate.getFullYear();

      const formattedDate =`${month}/${day}/${year}`;
      this.datePickerValue= `${year}-${month}-${day}`;
      return formattedDate;
    },
    openCalendarPicker(event) {
      event.target.showPicker();
    }
    }
  
};
</script>

<style scoped>
.datetime-picker {
  position: relative;
 
 
}

.defaultCalendar {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.calendar {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  display: none;
}

.calendar.show {
  display: block;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
}

.header button {
  background: none;
  border: none;
  cursor: pointer;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  background-color: #f9f9f9;
}

.day {
  padding: 10px;
}

.dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.date {
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.date:hover {
  background-color: #e0e0e0;
}

.current-date {
  background-color: #b3e5fc;
}

.selected-date {
  background-color: #ffcc80;
}

.time-picker {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  padding: 10px;
  text-align: center;
  background-color: #f0f0f0;
}

.time-picker select {
  padding: 5px;
}
.datePicker >>> .form-control{
  padding:0px;
  border-radius: 8px;
}
.datePicker >>> .reportrange-text{
  border:none;
}
</style>
<style>

</style>
